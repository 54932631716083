'use strict';

import $ from 'jquery';
import 'slick-carousel';
import ImagePreloader from 'image-preloader-promise';
import 'bootstrap-sass';
require('es6-promise').polyfill();


$(() => {

    // Set user agent
    const doc = document.documentElement;
    doc.setAttribute('data-useragent', navigator.userAgent);

    // Product carousel
    $('.product-list--carousel').slick({
        lazyLoad: 'ondemand',
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: '0',
        prevArrow: '<button type="button" class="slick-prev">></button>',
        nextArrow: '<button type="button" class="slick-next">></button>',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: true,
                    slidesToShow: 1
                }
            }
        ]
    });

    // Product image - tooltip
    $('#img-gallery').tooltip({
        animated: 'fade',
        placement: function (context, source) {
            const wWidth = $(window).width();

            if (wWidth > 768) {
                return "right";
            }

            return "bottom";
        },
        html: true,
    });

    // Product Gallery
    $('.product-gallery').on('click', 'a', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);

        if ($this.hasClass("active")) {
            return false;
        }

        $('.product-gallery a.active').removeClass('active');

        const imageSrc = $this.attr('href');
        const $gallery = $('#img-gallery');
        $this.addClass('preloadImage');
        $gallery.addClass('preloadImage');

        ImagePreloader.preloadImage(imageSrc)
            .then(() => {
                const srcBigImage = $this.data('big-image');

                // Load big Image
                ImagePreloader.preloadImage(srcBigImage)
                    .then(() => {
                        $gallery.attr('src', imageSrc);
                        $gallery.attr('data-original-title', `<img src="${srcBigImage}">`);
                        $this.removeClass('preloadImage');
                        $gallery.removeClass('preloadImage');
                        $this.addClass('active');
                    }).catch((error) => {
                    console.log('The image was not able to be loaded!');
                });
            })
            .catch((error) => {
                console.log('The image was not able to be loaded!');
            });
    });

    // Toggle class
    $('[data-toggle-class]').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        const toogleClass = $this.attr('data-toggle-class');
        const toogleElement = $this.attr('data-toogleElement') || 'body';
        $(toogleElement).toggleClass(toogleClass);
    });

    // Scroll to element
    $('[data-scroll-to]').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        const el = $this.attr('data-scroll-to') || 'html';
        $("html, body").animate({scrollTop: $(el).offset().top}, 500);
    });

    // Mobile Bar - show block
    $('[data-show-block]').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        const elId = $this.attr('href');
        const $el = $(elId);

        if (!$el.length) {
            return false
        }

        if ($this.hasClass("active")) {
            $this.removeClass('active');
            $el.stop().slideUp();
        } else {
            const $activeEl = $('a.active', '.mobile-bar');
            if ($activeEl.length) {
                const $activeBlock = $($activeEl.attr('href'));
                $activeBlock.slideUp('fast');
                $activeEl.removeClass('active');
            }

            $this.addClass('active');
            $el.stop().slideDown('fast');
        }
    });

    // Scroll to element
    $('[data-toggle]').on('click', (e) => {
        const $this = $(e.currentTarget);
        $('input', $this).prop('checked', true);
    });
});
